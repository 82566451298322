import React, { createContext, useState } from "react"

export const NavigationContext = createContext()

export const NavigationProvider = ({ children }) => {
  const [open, setToggle] = useState(false)
  const [regulationModal, setRegulationModal] = useState(false)
  const [speedPlan, setSpeedPlan] = useState("")

  return (
    <NavigationContext.Provider
      value={{
        open,
        setToggle,
        regulationModal,
        setRegulationModal,
        speedPlan,
        setSpeedPlan,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
