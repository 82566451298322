import React from "react"
import styled from "styled-components"

const SocialLink = ({ icon: Icon, href, title }) => (
  <Link href={href} title={title} target="_blank">
    <Icon />
  </Link>
)

const Link = styled.a`
  text-decoration: none;

  & svg,
  & path {
    fill: ${({ theme }) => theme.colors.white};
    transition: fill 0.2s ease-in;
  }

  &:hover svg,
  &:hover path {
    fill: ${({ theme }) => theme.colors.primary};
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
`

export default SocialLink
