import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Logo from "../Logo/Logo"
import Navigation from "../NavBar/NavBar"

const Header = () => (
  <Container>
    <Link to="/">
      <Logo />
    </Link>

    <Navigation />
  </Container>
)

export const Container = styled.header`
  max-width: ${({ theme }) => theme.sizes.maxWidth}px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`

export default Header
