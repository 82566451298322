import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

const GlobalStyle = createGlobalStyle`
  ${reset}
  /* other styles */

  body {
    font-family: BlinkMacSystemFont, -apple-system,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Montserrat",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      Helvetica,
      Arial,
      sans-serif;

      background-color: #fff;
  }

  button {
    cursor: pointer;
  }
`

export default GlobalStyle
