import React from "react"
import ReactModal from "react-modal"
import styled from "styled-components"

import RegulationText from "../RegulationText/RegulationText"
import CloseIcon from "../../images/cancel.svg"

import useNavigation from "../../hooks/useNavigation"
import RegulationTextPJ from "../RegulationText/RegulatioTextPJ"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    maxWidth: "1080px",
    padding: "20px",
  },
}

const RegulationModal = ({ props }) => {
  const { regulationModal, setRegulationModal, speedPlan } = useNavigation()

  const closeModal = () => setRegulationModal(false)

  return (
    <ReactModal
      isOpen={regulationModal}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <TitleText>REGULAMENTO</TitleText>
      {regulationModal === "fisic" && <RegulationText speed={speedPlan} />}

      {regulationModal === "pj" && <RegulationTextPJ speed={speedPlan} />}
      <CloseIconWrapper onClick={closeModal}>
        <CloseIcon />
      </CloseIconWrapper>
    </ReactModal>
  )
}

const TitleText = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 10px;
`

const CloseIconWrapper = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
`

export default RegulationModal
