import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Header from "../components/Header/Header"
import SEO from "../components/SEO/SEO"
import Footer from "../components/Footer/Footer"
import GlobalStyle from "../theme/global"
import theme from "../theme/theme"
import { NavigationProvider } from "../contexts/navigation"
import RegulationModal from "../components/RegulationModal/RegulationModal"

const Layout = ({
  children,
  description,
  lang,
  title,
  meta,
  header = true,
  footer = true,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <SEO description={description} title={title} lang={lang} meta={meta} />
      <GlobalStyle />

      <ThemeProvider theme={theme}>
        <NavigationProvider>
          <LayoutWrapper>
            {header && (
              <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
            )}
            {children}
            {footer && <Footer />}
          </LayoutWrapper>
          <RegulationModal />
        </NavigationProvider>
      </ThemeProvider>
    </>
  )
}

const LayoutWrapper = styled.div`
  border-top: 10px solid ${({ theme: appTheme }) => appTheme.colors.primary};
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
`

export default Layout
