const colors = {
  primary: "#55A449",
  secondary: "#132E37",
  white: "#fff",
  black: "#000",
  disable: "#707070",
  whiter: "#eee",
}

export default colors
