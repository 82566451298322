import React from "react"
import media from "styled-media-query"
import styled from "styled-components"

import LogoJnet from "../../images/logo_jnnet.svg"

const Logo = () => {
  return <LogoWrapper />
}

const LogoWrapper = styled(LogoJnet)`
  height: 100%;
  width: 5rem;

  ${media.lessThan("medium")`
    height: 100%;
    width: 3.75rem;
  `}
`

export default Logo
