import { useContext } from "react"

import { NavigationContext } from "../contexts/navigation"

const useNavigation = () => {
  const context = useContext(NavigationContext)
  if (!context) throw new Error("Context não definido")

  const {
    open,
    setToggle,
    regulationModal,
    setRegulationModal,
    speedPlan,
    setSpeedPlan,
  } = context

  return {
    open,
    setToggle,
    regulationModal,
    setRegulationModal,
    speedPlan,
    setSpeedPlan,
  }
}

export default useNavigation
