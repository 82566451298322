import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Link as GatsbyLink } from "gatsby"

import Facebook from "../../images/icons/facebook.svg"
import Instagram from "../../images/icons/instagram.svg"
import LogoFooter from "../../images/logo_footer.svg"

import SocialLink from "../SocialLink/SocialLink"

// TODO: Social link vira da api;
const social = [
  {
    title: "Facebook",
    href: "https://www.facebook.com/jnnetinternet",
    icon: Facebook,
  },
  {
    title: "Instagram",
    href: "https://www.instagram.com/jn_internet/",
    icon: Instagram,
  },
]

// TODO: Links virão da api;
const links = [
  {
    label: "Quem Somos",
    path: "/quem-somos",
  },
  {
    label: "Planos",
    path: "/planos",
  },
  {
    label: "Contato",
    path: "/contato",
  },
]

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <SocialLogoWrapper>
          <LogoFooter />
          <div>
            {social.map(item => (
              <SocialLink
                key={item.title}
                title={item.title}
                href={item.href}
                icon={item.icon}
              />
            ))}
          </div>
        </SocialLogoWrapper>

        <InfoWrapper>
          <LinksWrapper>
            {links.map(link => (
              <Link key={link.path} to={link.path}>
                {link.label}
              </Link>
            ))}
            <Redirect href="https://minhajnnet.jnnet.com.br" target="_blank">
              Área do cliente
            </Redirect>
          </LinksWrapper>

          <Address>Rua Pedro Nolasco, 115, Centro, João Neiva - ES</Address>

          <Number>0800 500 4650</Number>
        </InfoWrapper>
      </FooterContent>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 47px 20px;
`

const SocialLogoWrapper = styled.section`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & div {
    margin-top: 30px;
  }

  ${media.lessThan("medium")`
    align-items: center;
    margin-bottom: 20px;
  `}
`

const FooterContent = styled.section`
  max-width: ${({ theme }) => theme.sizes.maxWidth}px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${media.greaterThan("medium")`
    flex-direction: row;
  `}
`

const InfoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${media.lessThan("medium")`
    align-items: center;
  `}
`

const LinksWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
`

const Link = styled(GatsbyLink).attrs(({ theme }) => ({
  activeStyle: {
    fontWeight: "bold",
    color: theme.colors.white,
  },
}))`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 300;
  margin: 10px;

  ${media.greaterThan("medium")`
    &:not(:last-child) {
      margin-right: 15px;
    }

    font-size: 18px;
  `}
`

const Redirect = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 300;
  margin: 10px;

  ${media.greaterThan("medium")`
    &:not(:last-child) {
      margin-right: 15px;
    }

    font-size: 18px;
  `}
`

const Address = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 400;
  margin: 30px 0;

  ${media.lessThan("medium")`
      margin: 15px 0;
  `}
`

const Number = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 500;
`

export default Footer
