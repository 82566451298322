import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query"

import useNavigation from "../../hooks/useNavigation"

// TODO: Links virão da api;
const links = [
  {
    label: "Quem Somos",
    path: "/quem-somos",
  },
  {
    label: "Planos",
    path: "/planos",
  },
  {
    label: "Contato",
    path: "/contato",
  },
]

const Navigation = () => {
  const { open, setToggle } = useNavigation()

  const onClickButton = () => setToggle(!open)

  return (
    <>
      <OpenButton onClick={onClickButton}>
        <Stick />
        <Stick />
        <Stick />
      </OpenButton>
      <NavigationContainer className={open ? "active" : ""}>
        <CloseButton onClick={onClickButton}>
          <Stick />
          <Stick />
        </CloseButton>
        <NavigationList>
          {links.map(item => (
            <NavigationItem key={item.path} to={item.path}>
              {item.label}
            </NavigationItem>
          ))}

          <Redirect href="https://minhajnnet.jnnet.com.br" target="_blank">
            Área do cliente
          </Redirect>
        </NavigationList>
      </NavigationContainer>
    </>
  )
}

const Stick = styled.span`
  width: 30px;
  height: 3px;
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.primary};
  transform-origin: center center;
  position: absolute;

  &:focus {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

const OpenButton = styled.button`
  height: 21px;
  width: 30px;
  border: none;
  background-color: transparent;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  & ${Stick}:first-child {
    transform: translateY(-10px);
  }

  & ${Stick}:last-child {
    transform: translateY(10px);
  }

  ${media.greaterThan("medium")`
    display: none;
  `}
`

const CloseButton = styled(OpenButton)`
  margin-bottom: 15px;
  & ${Stick}:first-child {
    transform-origin: center center;
    transform: rotate(45deg);
  }

  & ${Stick}:last-child {
    transform-origin: center center;
    transform: rotate(-45deg);
  }
`

const NavigationContainer = styled.nav`
  height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  padding: 30px;
  right: 0;
  width: 40vw;
  min-width: 260px;
  max-width: 280px;
  z-index: 900;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 1px solid ${({ theme }) => theme.colors.whiter};
  transition: transform 0.3s ease-in-out;
  transform: translateX(322px);
  &.active {
    transform: translateX(0);
  }

  ${media.greaterThan("medium")`
    position: relative;
    bottom: 0;
    top: 0;
    height: auto;
    padding: 0;
    right: 0;
    width: auto;
    z-index: 0;
    max-width: none;
    border-left: none;
    transform: translateX(0px);

    &.active {
    transform: translateX(0);
  }
  `}
`

const NavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  align-items: stretch;

  ${media.greaterThan("medium")`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  `}
`

const NavigationItem = styled(Link).attrs(({ theme }) => ({
  activeStyle: {
    fontWeight: "bold",
    color: theme.colors.disable,
  },
}))`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.disable};
  font-weight: 300;
  font-size: 25px;
  margin: 10px;

  ${media.greaterThan("medium")`
    &:not(:last-child) {
      margin-right: 15px;
    }

    font-size: 15px;
  `}
`

const Redirect = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.disable};
  font-weight: 300;
  font-size: 25px;
  margin: 10px;

  ${media.greaterThan("medium")`
    &:not(:last-child) {
      margin-right: 15px;
    }

    font-size: 15px;
  `}
`

export default Navigation
